@charset "utf-8";
@use 'sass:color';

// Define defaults for each variable.

// Website text
$base-font-family: Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size:   18px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.6;

// Website colours
$text-color:       #111111;
$lighter-text-color: #424242;
$link-color:       #2a7ae2;
$header-color:     #353535;

// Website Layout Colors
$grey-border:      #424242; // Borders
$icon-color:       #424242;
$background-color: #fdfdfd;
$gradient-color:   #c7dcf7; // Header gradient

// Website Layout Sizes
$spacing-unit:     30px;
$content-width:    600px;
$sidebar-width:    260px;
$mobile-size:      600px;

// Use media queries like this:
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
