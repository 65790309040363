body {
  background-color: $background-color;
}

/**
 * Site header
 */
.social-wrapper {
  .social-media-list {
    list-style: none;
    margin-left: 0;
    margin-bottom: 0;
    background-color: white;

    li:not(:last-child) {
      margin-right: calc($spacing-unit / 2);
    }

    li {
      float: left;
      padding-top: 0;
      line-height: 1;

      a:hover {
        border-bottom: 1px solid $header-color;
      }
    }
  }
}

.sidebar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include media-query($mobile-size) {
    flex-direction: column;
  }

  .site-sidebar {
    min-height: $spacing-unit * 1.865;
    flex-basis: $sidebar-width;
    flex-shrink: 0;
    flex-grow: 0;
    border-right: 1px solid $grey-border;
    display: flex;
    flex-direction: column;

    // Positioning context for the mobile navigation icon
    position: relative;

    @include media-query($mobile-size) {
      border-right: 0;
      flex-basis: auto;
    }

    .site-header {
      border-bottom: 1px solid $grey-border;
      padding: $spacing-unit;

      @include media-query($mobile-size) {
        display: flex;
        flex-direction: row;
      }

      .thumbnail-wrapper {
        margin-bottom: $spacing-unit;
        flex-shrink: 0;

        @include media-query($mobile-size) {
          flex-basis: 110px;
          margin-bottom: 0;
          padding-right: $spacing-unit;
        }

        .social-media-list {
          display: none;

          @include media-query($mobile-size) {
            margin-top: calc($spacing-unit / 2);
            display: block;
          }
        }
        .site-thumbnail {
          width: 200px;
          height: 200px;

          @include media-query($mobile-size) {
            width: 110px;
            height: 110px;
          }
        }
      }
      .header-text {
        h1 {
          line-height: 1;
          margin-top: -2px;
          margin-bottom: calc($spacing-unit / 4);
        }
        .site-title {
          @include relative-font-size(1.6);

          &,
          &:visited {
            color: $header-color;
          }
        }
        .site-description {
          @include media-query($mobile-size) {
            margin-bottom: 0;
          }
        }
        .social-media-list {
          display: block;

          @include media-query($mobile-size) {
            display: none;
          }
        }
      }
    }
  }
  .site-nav {
    .nav-trigger {
        display: none;
    }

    .menu-icon {
      display: none;
    }

    .page-link {
      line-height: $base-line-height;

        &,
        &:visited {
          color: $header-color;
        }
    }

    .trigger {
      overflow: hidden;
    }

    .site-nav-list {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: $spacing-unit;
      justify-content: space-between;
      @include relative-font-size(1.3);

      @include media-query($mobile-size) {
        flex-direction: row;
        height: $spacing-unit;
        border-bottom: 1px solid $grey-border;
        padding: calc($spacing-unit / 2) $spacing-unit;
        @include relative-font-size(1.1);
      }
    }

    .social-media-list {
      display: block;
      padding-top: calc($spacing-unit / 2);
    }

    @include media-query($mobile-size) {
      position: relative;

      label[for="nav-trigger"] {
        position: absolute;
        border: 1px solid $grey-border;
        background-color: $background-color;
        right: calc($spacing-unit / 2);
        top: calc(-36px - calc($spacing-unit / 2));
        width: 36px;
        height: 36px;
        cursor: pointer;
        border-radius: 5px;
      }

      .menu-icon {
        display: block;
        width: 36px;
        height: 26px;
        line-height: 0;
        padding-top: 10px;
        text-align: center;
      }

      .trigger {
        transition: height 300ms, border-bottom-width 300ms 1ms;
        border-bottom: 0 solid $grey-border;
        height: 0;
      }

      input:checked ~ .trigger {
        height: 60px;
        border-bottom-width: 1px;
      }
    }
  }
}


/**
 * Page content
 */
.post-img-header {
  width: 100%;
  height: auto;
  margin-bottom: $spacing-unit;
  aspect-ratio: 4;
  border: 1px solid $grey-border;
}

.page-content {
  padding: $spacing-unit;
  flex-basis: $content-width;

  @include media-query($mobile-size) {
    flex-basis: auto;
    padding: calc($spacing-unit);
  }
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $lighter-text-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

.next-buttons {
  text-align: center;

  a {
    padding: 0 $spacing-unit;
  }
}


/**
 * Posts
 */
.youtube-embed {
  margin-bottom: calc($spacing-unit / 2);
  text-align: center;
}

.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  color: $header-color;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($mobile-size) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    color: $header-color;
    @include relative-font-size(2);

    @include media-query($mobile-size) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($mobile-size) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($mobile-size) {
      @include relative-font-size(1.125);
    }
  }
}

.post-excerpt {
  margin-bottom: $spacing-unit;

  .post-excerpt-content:after {
    content:"";
    display:block;
    clear:both;
  }

  .excerpt-title {
    color: $header-color;
    margin: 0;
  }

  .site-thumbnail {
    float: left;
    margin-right: calc($spacing-unit / 2);
  }

}
